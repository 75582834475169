var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.개인정보처리방침')
    }
  }), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [_c('div', {
    staticClass: "terms-container"
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원 산하 홈페이지에서 취급하는 모든 개인정보는 관련 법령에 근거하거나 정보주체의 동의에 의하여 수집")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("보유 및 처리되고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")]), _c('br')]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("[")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보보호법")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("]")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("은 이러한 개인정보의 취급에 대한 일반적 규범을 제시하고 있으며")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 이러한 법령의 규정에 따라 수집")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("보유 및 처리하는 개인정보를 공공업무의 적절한 수행과 이용자의 권익을 보호하기 위해 적법하고 적정하게 취급할 것입니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("본 개인정보취급방침은 서울산업진흥원에서 제공하는 서비스와 서울산업진흥원 아이디로 이용할 수 있는 홈페이지에 적용되며 다음과 같은 내용을 담고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원 홈페이지")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (www.sba.seoul.kr, www.sba.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- SETEC (www.setec.or.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울특별시창업스쿨")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (school.seoul.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울지식재산센터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (www.ipseoul.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울기술혁신센터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (seoul.rnbd.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("국제유통센터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (smc.sba.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("대관관리시스템")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (rent.sba.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("사업신청페이지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (mybiz.sba.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원의 개인정보처리방침은 아래와 같습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("1")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 처리목적")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 개인정보를 다음의 목적을 위해 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("홈페이지 회원가입 및 관리")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("회원 가입의사 확인")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회원제 서비스 제공에 따른 본인 식별")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("인증")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회원자격 유지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("관리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서비스 부정이용 방지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("각종 고지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("통지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("고충처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원사무 처리")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("민원인의 신원 확인")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원사항 확인")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("사실조사를 위한 연락")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("통지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("처리결과 통보 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("재화 또는 서비스 제공")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서비스 제공")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("콘텐츠 제공")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("맞춤 서비스 제공")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("본인인증")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("연령인증 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("4. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("마케팅 및 광고에의 활용")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("신규 서비스")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("제품")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(") ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개발 및 맞춤 서비스 제공")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이벤트 및 광고성 정보 제공 및 참여기회 제공")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서비스의 유효성 확인")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("5. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 수탁업무관련")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 수탁업무")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("중소기업 육성")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("창업 등")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("와 관련하여 개인신용정보조회")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("컨설팅")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("금융")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("사업지원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원처리 및 법령상 의무이행 등의 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("6. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("직원채용")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("인사관리 및 임직원 정보교류 등")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("직원채용")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("인사관리 및 임직원에 대한 정보교류 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("7. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인영상정보")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("범죄의 예방 및 수사")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("2")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("처리하는 개인정보의 항목")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 회원가입")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 수탁업무수행")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("각종서비스 제공을 위해 다음의 개인정보 항목을 처리하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 항목")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("① 필수항목")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인식별정보")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("성명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("주소")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("핸드폰번호 등 연락처")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("), ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("기업정보")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회사명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("대표자명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("대표이메일")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("사업자등록번호 등")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("), ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("상품 정보")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("상품명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울어워드 선정 정보 등")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("② 선택항목")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 외에 신청서에 기재된 정보 또는 고객이 제공한 정보")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("수집방법")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("홈페이지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서면양식")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("팩스")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("전화")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("상담게시판")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이메일")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("배송요청")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("고객센터 등의 문의사항을 통한 수집")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 처리 및 보유기간")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 법령에 따른 개인정보 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용기간 내에서 개인정보를 처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(",")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("보유합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 수탁업무와 관련한 개인정보 및 기업정보는 수집")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용에 관한 동의일로부터 서울시 수탁업무 종료일까지 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("1")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조의 이용목적을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 수탁업무 종료일 이후에는 분쟁해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법령상 의무이행 및 우리재단 리스크 관리업무만을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회원가입 및 관리목적으로 수집된 개인정보는 고객의 회원가입일로부터 회원탈퇴일까지 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용하게 됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회원탈퇴일 후에는 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("1")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조의 목적과 관련된 사업이력")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법령상 의무이행 만을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("재단 임직원 등의 개인정보는 퇴직 후에도 사고예방")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조사")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법령상 의무이행을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("4")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("자 제공")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 정보주체의 동의")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법률의 특별한 규정 등 개인정보 보호법 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("17")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조 및 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("18")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조에 해당하는 경우에만 개인정보를 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("자에게 제공합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('table', {
    staticClass: "MsoNormalTable",
    staticStyle: {
      "margin-left": "-3.25pt",
      "border-collapse": "collapse",
      "table-layout": "fixed"
    },
    attrs: {
      "border": "1",
      "cellspacing": "0",
      "cellpadding": "0",
      "data-workseditor": "Table"
    }
  }, [_c('tbody', [_c('tr', {
    staticStyle: {
      "height": "14.3pt"
    }
  }, [_c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border": "1pt solid windowtext",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black"
    }
  }, [_vm._v("개인정보")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black"
    }
  }, [_c('br')])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black"
    }
  }, [_vm._v("파일명")])]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-image": "initial",
      "border-left": "none",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black"
    }
  }, [_vm._v("제공받는자")])]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-image": "initial",
      "border-left": "none",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black"
    }
  }, [_vm._v("제공근거")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black"
    }
  }, [_c('br')])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#F7F7F7"
    }
  }, [_vm._v("및")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black",
      "background": ""
    }
  })]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black"
    }
  }, [_vm._v("목적")])]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-image": "initial",
      "border-left": "none",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#"
    }
  }, [_vm._v("제공항목")])]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "92.85pt",
      "border-top": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-image": "initial",
      "border-left": "none",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "124"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#"
    }
  }, [_vm._v("제공받는자")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black",
      "background": "#"
    }
  })]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#"
    }
  }, [_vm._v("보유")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black"
    }
  }, [_c('br')])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#F7F7F7"
    }
  }, [_vm._v("및")])]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "font-family": "\"Noto Sans KR\",sans-serif",
      "color": "black",
      "background": "#F7F7F7"
    }
  })]), _c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#F7F7F7"
    }
  }, [_vm._v("이용기간")])]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "94.1pt",
      "border-top": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-image": "initial",
      "border-left": "none",
      "background": "rgb(231, 230, 230)",
      "padding": "0cm 4.95pt",
      "height": "14.3pt",
      "white-space": "normal"
    },
    attrs: {
      "width": "125"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('b', {
    staticStyle: {}
  }, [_c('span', {
    staticStyle: {
      "font-size": "10.5pt",
      "color": "black",
      "background": "#F7F7F7"
    }
  }, [_vm._v("담당부서")])]), _c('span', {
    staticStyle: {}
  })])])]), _c('tr', {
    staticStyle: {
      "height": "9.95pt"
    }
  }, [_c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-left": "1pt solid windowtext",
      "border-image": "initial",
      "border-top": "none",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("홈페이지")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black"
    }
  }, [_c('br')]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("회원정보")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("나이스신용평가정보")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("주")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("회원동의")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black"
    }
  }, [_c('br')]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("본인인증")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("이름")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(",")]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("성별")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black"
    }
  }, [_c('br')]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("생년월일")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(",")]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("핸드폰번호")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "92.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "124"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("신용평가기관에서")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("이미")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("보유하고")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("있는")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("정보이기")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("때문에")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("별도")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("보유하지")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("않음")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "94.1pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "9.95pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "125"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("정보서비스")]), _c('span', {
    staticStyle: {}
  })])])]), _c('tr', {
    staticStyle: {
      "height": "18.65pt"
    }
  }, [_c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-right": "1pt solid windowtext",
      "border-bottom": "1pt solid windowtext",
      "border-left": "1pt solid windowtext",
      "border-image": "initial",
      "border-top": "none",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("홈페이지회원정보")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("나이스신용평가정보")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("주")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("회원동의")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black"
    }
  }, [_c('br'), _c('span', {
    staticStyle: {
      "background": "white"
    }
  }, [_vm._v("I-PIN")])]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("인증")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "83.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "112"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("I-PIN")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black"
    }
  }, [_c('br'), _c('span', {
    staticStyle: {
      "background": "white"
    }
  }, [_vm._v("(")])]), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("개인식별번호")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "92.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "124"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("신용평가기관에서")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("이미")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("보유하고")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("있는")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("정보이기")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("때문에")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("별도")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("보유하지")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("않음")]), _c('span', {
    staticStyle: {
      "font-family": "\"Noto Sans KR\", sans-serif",
      "color": "black",
      "background": "white"
    }
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  })])]), _c('td', {
    staticStyle: {
      "width": "94.1pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "1pt solid windowtext",
      "border-right": "1pt solid windowtext",
      "padding": "0cm 4.95pt",
      "height": "18.65pt",
      "background-color": "rgb(255, 255, 255)",
      "white-space": "normal"
    },
    attrs: {
      "width": "125"
    }
  }, [_c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "background": "white"
    }
  }, [_vm._v("정보서비스")]), _c('span', {
    staticStyle: {}
  })])])])])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("(※ ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("기타 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("자 제공내역은 홈페이지를 통하여")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" PDF")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("로 안내")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("5")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보처리 위탁")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 원칙적으로 고객의 동의없이 해당 개인정보의 처리를 타인에게 위탁하지 않습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("6")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("정보주체의 권리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("의무 및 그 행사방법")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("고객은 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("정보주체는")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("재")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(‘www.sba.seoul.kr’")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이하")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" ‘")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("① 개인정보 열람요구")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("② 오류 등이 있을 경우 정정 및 삭제의 요청")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("③ 처리정지 요구")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("④ 개인정보의 조회")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("수정")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("해지 및 삭제 등의 요청은 회원정보수정 및 회원탈퇴 등을 클릭하여 본인 확인 절차를 거치신 후 직접 열람")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("정정")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("혹은 탈퇴가 가능합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("1")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("항에 따른 권리 행사는")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("재")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(‘www.sba.seoul.kr’")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이하")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" ‘")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(") ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("에 대해 개인정보 보호법 시행규칙 별지 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("8")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("호 서식에 따라 서면")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("전자우편")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("모사전송")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(Fax) ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("등을 통하여 하실 수 있으며 서울산업진흥원은 이에 대해 지체 없이 조치하겠습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 서울산업진흥원은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("라")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("제가항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이 경우 개인정보 보호법 시행규칙 별지 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("11")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("호 서식에 따른 위임장을 제출하셔야 합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("7")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보처리의 정지요구")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("고객은 서울산업진흥원에 대하여 자신의 개인정보 처리의 정리를 요구할 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("다만")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("다음 각 호의 어느 하나에 해당하는 경우에는 서울산업진흥원은 해당 사유를 고객에게 알리고")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("처리정지 요구를 거절할 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("다른 사람의 생명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보를 처리하지 아니하면 고객과 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 고객이 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("8")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 파기")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("다음의 정보에 대하여는 아래의 사유로 명시한 기간 동안 보존합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("① 개인정보 및 기업정보 금융거래 및 서울시 수탁업무목적으로 처리하는 개인정보 및 기업정보는 금융거래 및 서울시 수탁업무목적을 달성한 날까지 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("목적 달성일 후에는 금융사고 예방")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조사")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁 해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원처리 및 법령상 의무이행 만을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("② 회원 가입 및 관리 목적으로 수집된 개인정보 고객의 회원 가입일로부터 회원 탈퇴일까지 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단 회원 탈퇴일 후에도 사고 예방")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조사")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분쟁 해결")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("민원 처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법령상 의무이행 만을 위하여 보유")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이용됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("파기의 절차")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("기한 및 방법은 다음과 같습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("파기의 절차")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("기한 및 방법은 다음과 같습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("① 파기절차")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("이용자가 입력한 정보는 목적 달성 후 별도의")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" DB")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("에 옮겨져")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("종이의 경우 별도의 서류")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(") ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이 때")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", DB")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("② 파기기한")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 5")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일 이내에")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 처리 목적 달성")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("해당 서비스의 폐지")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 5")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일 이내에 그 개인정보를 파기합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("③ 파기방법")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("9")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 안전성 확보 조치")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 개인정보보호법 제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("29")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조에 따라 다음과 같이 안전성 확보에 필요한 기술적")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("/")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("관리적 및 물리적 조치를 하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 취급 직원의 최소화 및 교육")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("정기적인 자체 감사 실시")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 취급 관련 안정성 확보를 위해 정기적")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("반기")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 1")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("회")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("으로 자체 감사를 실시하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("내부관리계획의 수립 및 시행")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("4. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보의 암호화")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("이용자의 개인정보 비밀번호는 암호화 되어 저장 및 관리되고 있어")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("5. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("해킹 등에 대비한 기술적 대책")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("·")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("/")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("물리적으로 감시 및 차단하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("6. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보에 대한 접근 제한")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(",")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("변경")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(",")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("7. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("접속기록의 보관 및 위변조 방지")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보처리시스템에 접속한 기록을 최소")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 6")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개월 이상 보관")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("관리하고 있으며")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("접속 기록이 위변조 및 도난")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분실되지 않도록 보안기능 사용하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("8. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("문서보안을 위한 잠금장치 사용")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보가 포함된 서류")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("9. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("비인가자에 대한 출입 통제")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("운영하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("10")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보보호 책임자")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원은 개인정보 처리에 관한 업무를 총괄해서 책임지고")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보보호책임자 ")]), _c('b', {
    staticStyle: {}
  }, [_c('span')])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("성 명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("김용상")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("직 책")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("마케팅지원본부장")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("연 락 처")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 02-2657-5800")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보보호 담당부서 ")]), _c('b', {
    staticStyle: {}
  }, [_c('span')])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("부 서 명")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("판로개척팀")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("담 당 자")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("임주혜")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("전화번호")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 02-2657-5703")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("주 소")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("우")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")07563 ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울시 강서구 공항대로")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("61")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("길")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 29 (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("등촌동")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("정보주체께서는 서울산업진흥원의 서비스")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("또는 사업")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("을 이용하시면서 발생한 모든 개인정보 보호 관련 문의")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("불만처리")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("재")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("(‘www.sba.seoul.kr’")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이하")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" ‘")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("서울산업진흥원")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("11")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 처리방침 변경")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보처리방침은 시행일로부터 적용되며")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("법령 및 방침에 따른 변경내용의 추가")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("삭제 및 정정이 있는 경우에는 변경사항의 시행")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 7")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일 전부터 공지사항을 통하여 고지할 것입니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("단")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("분야별로 관리되는 개인정보파일 수량 변동으로 인한 변경사유 발생시는 고지를 생략합니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("제")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("12")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("조")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("권익침해 구제방법")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(", ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(". ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("이 밖에 기타 개인정보침해의 신고 및 상담에 대하여는 아래의 기관에 문의하시기를 바랍니다")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(".")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("1. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 침해신고센터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("국번없이")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(")118 (privacy.kisa.or.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("2. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개인정보 분쟁조정위원회")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : (")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("국번없이")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(") 1833-6972 (www.kopico.go.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("3. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("대검찰청 첨단범죄수사과")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 02-3480-2000 (www.spo.go.kr, cybercid@spo.go.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("4. ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("경찰청 사이버테러대응센터")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 1566-0112 (www.netan.go.kr)")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v(" ")])]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("공고일자")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 2012")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("년")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("월")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 21")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("시행일자")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 2012")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("년")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 3")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("월")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 30")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일")]), _c('span', {
    staticStyle: {}
  })]), _c('p', {
    staticClass: "MsoNormal",
    staticStyle: {
      "margin": "0cm 0cm 8pt",
      "text-align": "justify"
    }
  }, [_c('span', {
    staticStyle: {}
  }, [_vm._v("- ")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("개정일자")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" : 2015")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("년")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 10")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("월")]), _c('span', {
    staticStyle: {}
  }, [_vm._v(" 7")]), _c('span', {
    staticStyle: {}
  }, [_vm._v("일")]), _c('span', {
    staticStyle: {}
  }), _vm._v("​")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }