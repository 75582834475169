var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-content"
    }
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('router-view', {
    attrs: {
      "name": "board",
      "code": "notice",
      "skin": "border-normal"
    }
  }), _c('div', {
    staticClass: "board-container"
  }, [_c('v-simple-table', {
    staticClass: "table-board align-center"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "d-none d-lg-table-cell",
    staticStyle: {
      "width": "7%"
    }
  }, [_vm._v("번호")]), _c('th', {
    staticClass: "table-board__tit",
    staticStyle: {
      "width": "66%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("이름")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticStyle: {
      "width": "7%"
    }
  }, [_vm._v("조회")])])]), _c('tbody', [_vm._l(_vm.notices, function (notice) {
    return _c('tr', {
      key: notice._id,
      staticStyle: {
        "cursor": "pointer",
        "background-color": "#fffcf6"
      },
      on: {
        "click": function ($event) {
          return _vm.details(notice);
        }
      }
    }, [_c('td', {
      staticClass: "d-none d-lg-table-cell"
    }, [_vm._v("공지")]), _c('td', {
      staticClass: "table-board__tit"
    }, [_c('div', {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(notice.subject))])]), _c('td', [_vm._v(_vm._s(notice.writer.name))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(notice.createdAt).format("YYYY-MM-DD")))]), _c('td', [_vm._v(_vm._s(notice.viewCount))])]);
  }), _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: board._id,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.details(board);
        }
      }
    }, [_c('td', {
      staticClass: "d-none d-lg-table-cell"
    }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))]), _c('td', {
      staticClass: "table-board__tit"
    }, [_c('div', {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(board.subject))])]), _c('td', [_vm._v(_vm._s(board.writer.name))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(board.createdAt).format("YYYY-MM-DD")))]), _c('td', [_vm._v(_vm._s(board.viewCount))])]);
  })], 2)]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }