export function ExpansionPanel() {
    const panelHeaderIconBtn = document.querySelectorAll('.v-expansion-panel-header__icon');
    const panelTextMoreBtn = document.querySelectorAll('.panel-text-more__btn');

    // v-expansion-panel-header__icon
    for(var i=0; i<panelHeaderIconBtn.length; i++){
        panelHeaderIconBtn[i].addEventListener('click', panelHeaderIconClick);
    }
    function panelHeaderIconClick() {
        if (this.parentNode.classList.contains('v-expansion-panel-header--active') == true) {
            this.previousSibling.classList.remove('active');

            var panelContentMore = this.parentNode.parentNode.querySelectorAll('.panel-content-more');
            for (var i = 0; i < panelContentMore.length; i++) {
                panelContentMore[i].classList.add('d-none');
            }
        }else {
            var panelContentMore = this.parentNode.parentNode.querySelectorAll('.panel-content-more');
            for (var i = 0; i < panelContentMore.length; i++) {
                panelContentMore[i].classList.add('d-none');
            }
        }
    }

    // panel-text-more__btn
    for(var i=0; i<panelTextMoreBtn.length; i++){
        panelTextMoreBtn[i].addEventListener('click', panelTextMoreClick);
    }
    function panelTextMoreClick() {
        if (this.parentNode.parentNode.classList.contains('v-expansion-panel-header--active') == true) {
            this.parentNode.classList.toggle('active');

            if (this.parentNode.classList.contains('active') == true) {
                var panelContentMore = this.parentNode.parentNode.parentNode.querySelectorAll('.panel-content-more');
                for (var i = 0; i < panelContentMore.length; i++) {
                    panelContentMore[i].classList.remove('d-none');
                }
            } else {
                var panelContentMore = this.parentNode.parentNode.parentNode.querySelectorAll('.panel-content-more');
                for (var i = 0; i < panelContentMore.length; i++) {
                    panelContentMore[i].classList.add('d-none');
                }
            }
        } else {
            panelTextMoreBtn.parentNode.classList.remove('active');

            var panelContentMore = this.parentNode.parentNode.parentNode.querySelectorAll('.panel-content-more');
            for (var i = 0; i < panelContentMore.length; i++) {
                panelContentMore[i].classList.add('d-none');
            }

        }
    }
}
