<template>
    <div id="page-content">
        <div class="contents">
            <v-row class="row--medium">
                <!-- S: 이미지 -->
                <v-col cols="12" md="6" lg="4">
                    <div class="about-img" :style="'background-image: url(' + setting.site.contentImage + ')'"></div>
                </v-col>
                <!-- E: 이미지 -->
                <!-- S: 회사소개 -->
                <v-col cols="12" md="6" lg="4">
                    <v-expansion-panels
                        v-model="panel"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                회사소개
                                <div class="panel-text-more">
                                    <button @click.stop="contentView = !contentView" class="panel-text-more__btn"></button>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="page-text font-weight-light" v-html="setting.site.contentOne"></p>
                                <div class="panel-content-more" v-show="contentView">
                                    <p class="page-text font-weight-light" v-html="setting.site.contentTwo"></p>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <!-- E: 회사소개 -->
                <!-- S: 회사연혁 -->
                <v-col cols="12" md="12" lg="4">
                    <v-expansion-panels
                        v-model="panel2"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                회사연혁
                                <div class="panel-text-more">
                                    <button @click.stop="historyView = !historyView" class="panel-text-more__btn"></button>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="history-items">
                                    <div class="history-item" v-for="item in setting.site.historyOne" :key="item.id">
                                        <div class="history-item__tit">{{item.year}}</div>
                                        <div class="history-item__txt">
                                            <p class="page-text font-weight-light">
                                                <span v-if="item.enContent">{{item.enContent}}</span><br/>
                                                <span v-if="item.krContent">{{item.krContent}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- S: 더보기 -->
                                <div class="panel-content-more mt-5" v-show="historyView">
                                    <div class="history-items">
                                        <div class="history-item" v-for="item in setting.site.historyTwo" :key="item.id">
                                            <div class="history-item__tit">{{item.year}}</div>
                                            <div class="history-item__txt">
                                                <p class="page-text font-weight-light">
                                                    <span v-if="item.enContent">{{item.enContent}}</span><br/>
                                                    <span v-if="item.krContent">{{item.krContent}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- E: 더보기 -->
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <!-- E: 회사연혁 -->
            </v-row>
        </div>
    </div>
</template>

<script>
import { ExpansionPanel } from "@/plugins/expansionPanel.js"; // 스크립트
import api from "@/api";

export default {
    components: {
    },
    data: () => ({
        panel: [0],
        panel2: [0],
        setting : null,
        contentView : false,
        historyView : false,
    }),
    created() {
        this.getSetting();
    },
    mounted() {

        this.expansionPanel();
    },
    methods: {
        async getSetting() {
            try {
                var { setting } = await api.v1.setting.get({});
                setting.site.contentOne = setting.site.contentOne.replace(/\n/gi,'<br/>');
                setting.site.contentTwo = setting.site.contentTwo.replace(/\n/gi,'<br/>');

                this.setting = setting;
            } catch (error) {
                this.handleError(error);
            }
        },
        expansionPanel: function() {
            this.$nextTick(function() {
                ExpansionPanel(); // ExpansionPanel 스크립트 로드
            });
        },
    },
};
</script>