<template>
    <div id="page-content">
        <div class="contents">
            <router-view name="board" code="notice" skin="border-normal"></router-view>

            <!-- S: 퍼블확인용 List -->
            <div class="board-container">
                <v-simple-table class="table-board align-center">
                    <thead>
                        <tr>
                            <th style="width:7%" class="d-none d-lg-table-cell">번호</th>
                            <th style="width:66%" class="table-board__tit">제목</th>
                            <th style="width:10%">이름</th>
                            <th style="width:10%">작성일</th>
                            <th style="width:7%">조회</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="notice in notices" :key="notice._id" style="cursor:pointer; background-color:#fffcf6;" @click="details(notice)">
                            <td class="d-none d-lg-table-cell">공지</td>
                            <td class="table-board__tit"><div class="ellipsis">{{ notice.subject }}</div></td>
                            <td>{{ notice.writer.name }}</td>
                            <td>{{ $dayjs(notice.createdAt).format("YYYY-MM-DD") }}</td>
                            <td>{{ notice.viewCount }}</td>
                        </tr>
                        <tr v-for="(board, index) in boards" :key="board._id" style="cursor:pointer;" @click="details(board)">
                            <td class="d-none d-lg-table-cell">{{ (page - 1) * limit + index + 1 }}</td>
                            <td class="table-board__tit"><div class="ellipsis">{{ board.subject }}</div></td>
                            <td>{{ board.writer.name }}</td>
                            <td>{{ $dayjs(board.createdAt).format("YYYY-MM-DD") }}</td>
                            <td>{{ board.viewCount }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <div class="board-bottom">
                    <pagination-component v-model="page" :count="pageCount" @input="search(true)"/>
                </div>
            </div>
            <!-- E: 퍼블확인용 List -->
        </div>
    </div>
</template>

<script>
import api from "@/api";
import paginationComponent from "@/components/client/control/pagination-component.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        paginationComponent,
        NaverSmarteditor,
    },
    data(){
        return {
            notices: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,
            boards: [],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { page: this.page }
                });
            }

            var { boards: notices } = await api.v1.boards.gets({ params: { isNotice: true, code: "board" } });
            this.notices = notices;

            var { summary, boards } = await api.v1.boards.gets({ 
                params: { isNotice: false, code: "board" },
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                }
            });

            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        details(board){
            this.$router.push(`/board/${board._id}`);
        }
    }
};
</script>