var render = function render(){
  var _vm$board$content, _vm$board$content$rep, _vm$board$content2, _vm$board$content2$re;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    attrs: {
      "id": "page-content"
    }
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "photo-view-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.board.images, function (image, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: image,
        expression: "image"
      }],
      key: index,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "photo-view-slide__img",
      style: {
        backgroundImage: `url('${image}')`
      }
    })]);
  }), 0), _c('div', {
    staticClass: "swiper-pagination"
  }), _c('button', {
    staticClass: "prev",
    attrs: {
      "type": "button"
    }
  }), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button"
    }
  })])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "text-18 text-md-16"
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "text-items"
  }, [_c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("프로젝트명")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.subject))])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("의뢰인")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.client))])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("주소")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.address))])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("면적")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.area))])])])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "text-items"
  }, [_c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit w-100"
  }, [_c('strong', [_vm._v("마감재")])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("벽")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.wall))])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("바닥")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.floor))])])]), _c('div', {
    staticClass: "text-item"
  }, [_c('div', {
    staticClass: "text-item__tit"
  }, [_c('strong', [_vm._v("천정")])]), _c('div', {
    staticClass: "text-item__txt"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.board.ceiling))])])])])])], 1)], 1)], 1)], 1), _c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panel2,
      callback: function ($$v) {
        _vm.panel2 = $$v;
      },
      expression: "panel2"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "text-18 text-md-16"
  }, [_vm._v("프로젝트 설명")]), _c('div', {
    staticClass: "panel-text-more"
  }, [_c('button', {
    staticClass: "panel-text-more__btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  })])]), _c('v-expansion-panel-content', [_c('p', {
    staticClass: "page-text font-weight-light",
    domProps: {
      "innerHTML": _vm._s((_vm$board$content = _vm.board.content) === null || _vm$board$content === void 0 ? void 0 : (_vm$board$content$rep = _vm$board$content.replace) === null || _vm$board$content$rep === void 0 ? void 0 : _vm$board$content$rep.call(_vm$board$content, /\n/g, '<br/>'))
    }
  }), _c('div', {
    staticClass: "panel-content-more d-none"
  }, [_c('p', {
    staticClass: "page-text font-weight-light",
    domProps: {
      "innerHTML": _vm._s((_vm$board$content2 = _vm.board.content2) === null || _vm$board$content2 === void 0 ? void 0 : (_vm$board$content2$re = _vm$board$content2.replace) === null || _vm$board$content2$re === void 0 ? void 0 : _vm$board$content2$re.call(_vm$board$content2, /\n/g, '<br/>'))
    }
  })])])], 1)], 1)], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }