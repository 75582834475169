var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-content"
    }
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "contact-wrap"
  }, [_c('div', {
    staticClass: "contact-container"
  }, [_c('div', {
    staticClass: "logo-img"
  }, [_c('router-link', {
    staticClass: "logo-img__inner",
    attrs: {
      "to": "/"
    }
  })], 1), _c('v-row', {
    staticClass: "row--medium"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "contact-items-wrap"
  }, [_c('div', {
    staticClass: "contact-items"
  }, [_c('div', {
    staticClass: "contact-item"
  }, [_c('a', {
    staticClass: "contact-item__inner",
    attrs: {
      "href": "https://www.instagram.com/bidam_design/",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon icon-insta"
  }), _c('strong', {
    staticClass: "contact-item__tit"
  }, [_vm._v("디자인비담 공식 인스타그램")])])]), _c('div', {
    staticClass: "contact-item"
  }, [_c('a', {
    staticClass: "contact-item__inner",
    attrs: {
      "href": "http://www.hantoday.net/news/articleList.html?page=1&total=4&box_idxno=&sc_area=I&sc_word=dyoung10",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon"
  }, [_vm._v("H")]), _c('strong', {
    staticClass: "contact-item__tit"
  }, [_vm._v("한국투데이 최동영 기자 – 분야별 전문가")])])])])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "mb-10"
  }, [_c('h2', {
    staticClass: "text-18 font-weight-600"
  }, [_vm._v("Contact Us")])]), _c('v-simple-table', {
    staticClass: "simple-table table-md-column"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("기업명")]), _c('td', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "기업명을 입력해주세요"
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  })], 1), _c('th', [_vm._v("담당자")]), _c('td', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "담당자를 입력해주세요"
    },
    model: {
      value: _vm.form.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "name", $$v);
      },
      expression: "form.writer.name"
    }
  })], 1)]), _c('tr', [_c('th', [_vm._v("이메일")]), _c('td', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "00000@email.com"
    },
    model: {
      value: _vm.form.writer.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "email", $$v);
      },
      expression: "form.writer.email"
    }
  })], 1), _c('th', [_vm._v("연락처")]), _c('td', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "000-0000-0000"
    },
    model: {
      value: _vm.form.writer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "phone", $$v);
      },
      expression: "form.writer.phone"
    }
  })], 1)]), _c('tr', [_c('th', [_vm._v("제작구분")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Residence",
      "value": "Residence"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Retail",
      "value": "Retail"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Office",
      "value": "Office"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Etc",
      "value": "Etc"
    }
  })], 1)], 1)]), _c('tr', [_c('th', [_vm._v("문의내용")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "문의내용, 예상 제작비용"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])])]), _c('div', {
    staticClass: "mt-30 mt-md-20"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "tile": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v("문의하기")])], 1)], 1)], 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }