var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-content"
    }
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('v-row', {
    staticClass: "row--medium"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "about-img",
    style: 'background-image: url(' + _vm.setting.site.contentImage + ')'
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" 회사소개 "), _c('div', {
    staticClass: "panel-text-more"
  }, [_c('button', {
    staticClass: "panel-text-more__btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.contentView = !_vm.contentView;
      }
    }
  })])]), _c('v-expansion-panel-content', [_c('p', {
    staticClass: "page-text font-weight-light",
    domProps: {
      "innerHTML": _vm._s(_vm.setting.site.contentOne)
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contentView,
      expression: "contentView"
    }],
    staticClass: "panel-content-more"
  }, [_c('p', {
    staticClass: "page-text font-weight-light",
    domProps: {
      "innerHTML": _vm._s(_vm.setting.site.contentTwo)
    }
  })])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panel2,
      callback: function ($$v) {
        _vm.panel2 = $$v;
      },
      expression: "panel2"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" 회사연혁 "), _c('div', {
    staticClass: "panel-text-more"
  }, [_c('button', {
    staticClass: "panel-text-more__btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.historyView = !_vm.historyView;
      }
    }
  })])]), _c('v-expansion-panel-content', [_c('div', {
    staticClass: "history-items"
  }, _vm._l(_vm.setting.site.historyOne, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "history-item"
    }, [_c('div', {
      staticClass: "history-item__tit"
    }, [_vm._v(_vm._s(item.year))]), _c('div', {
      staticClass: "history-item__txt"
    }, [_c('p', {
      staticClass: "page-text font-weight-light"
    }, [item.enContent ? _c('span', [_vm._v(_vm._s(item.enContent))]) : _vm._e(), _c('br'), item.krContent ? _c('span', [_vm._v(_vm._s(item.krContent))]) : _vm._e()])])]);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.historyView,
      expression: "historyView"
    }],
    staticClass: "panel-content-more mt-5"
  }, [_c('div', {
    staticClass: "history-items"
  }, _vm._l(_vm.setting.site.historyTwo, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "history-item"
    }, [_c('div', {
      staticClass: "history-item__tit"
    }, [_vm._v(_vm._s(item.year))]), _c('div', {
      staticClass: "history-item__txt"
    }, [_c('p', {
      staticClass: "page-text font-weight-light"
    }, [item.enContent ? _c('span', [_vm._v(_vm._s(item.enContent))]) : _vm._e(), _c('br'), item.krContent ? _c('span', [_vm._v(_vm._s(item.krContent))]) : _vm._e()])])]);
  }), 0)])])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }