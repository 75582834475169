<template>
    <div v-if="board" id="page-content">
        <div class="contents">
            <v-row class="row--large">
                <!-- S: 이미지슬라이드 -->
                <v-col cols="12" lg="6">
                    <div class="photo-view-slide">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div v-for="(image, index) in board.images" :key="index" v-show="image" class="swiper-slide">
                                    <!-- 이미지는 background-image로 넣어주세요 -->
                                    <div class="photo-view-slide__img" :style="{ backgroundImage: `url('${image}')`}"></div>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>
                            <button type="button" class="prev"></button>
                            <button type="button" class="next"></button>
                        </div>
                    </div>
                </v-col>
                <!-- E: 이미지슬라이드 -->
                <!-- S: 내용 -->
                <v-col cols="12" lg="6">
                    <!-- S: 프로젝트 -->
                    <v-expansion-panels
                        v-model="panel"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="text-18 text-md-16">{{ board.subject }}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="row--small">
                                    <v-col cols="12" lg="6">
                                        <div class="text-items">
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>프로젝트명</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.subject }}</p>
                                                </div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>의뢰인</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.client }}</p>
                                                </div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>주소</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.address }}</p>
                                                </div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>면적</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.area }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="text-items">
                                            <div class="text-item">
                                                <div class="text-item__tit w-100"><strong>마감재</strong></div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>벽</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.wall }}</p>
                                                </div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>바닥</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.floor }}</p>
                                                </div>
                                            </div>
                                            <div class="text-item">
                                                <div class="text-item__tit"><strong>천정</strong></div>
                                                <div class="text-item__txt">
                                                    <p class="page-text">{{ board.ceiling }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- E: 프로젝트 -->
                    <!-- S: 프로젝트설명 -->
                    <v-expansion-panels
                        v-model="panel2"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="text-18 text-md-16">프로젝트 설명</span>
                                <div class="panel-text-more">
                                    <button @click.stop class="panel-text-more__btn"></button>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="page-text font-weight-light" v-html="board.content?.replace?.(/\n/g, '<br/>')"></p>
                                <div class="panel-content-more d-none">
                                    <p class="page-text font-weight-light" v-html="board.content2?.replace?.(/\n/g, '<br/>')"></p>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- E: 프로젝트설명 -->
                </v-col>
                <!-- E: 내용 -->
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import { ExpansionPanel } from "@/plugins/expansionPanel.js"; // 스크립트

export default {
    components: {
    },
    data(){
        return {
            board: null,
            panel: [0],
            panel2: [0],
        }
    },
    async mounted(){
        
        await this.init();

        this.$nextTick(() => {
            this.expansionPanel();
            this.swiper = new Swiper ('.photo-view-slide .swiper-container', {
                centeredSlides: true,
                spaceBetween : 0,
                // autoplay: {
                //     delay: 3000,
                //     disableOnInteraction: false,
                // },
                speed: 500,
                loop: true,
                pagination: {
                    el: '.photo-view-slide .swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.photo-view-slide .next',
                    prevEl: '.photo-view-slide .prev'
                },
            });
        });
    },
    methods: {
        async init(){
            var { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.board = board;
            this.$store.dispatch("remember", board);
        },
        expansionPanel: function() {
            this.$nextTick(function() {
                ExpansionPanel(); // ExpansionPanel 스크립트 로드
            });
        },
    },
};
</script>