<template>
    <div id="page-content">
        <div class="contents">
            <router-view name="board" code="notice" skin="border-normal"></router-view>

            <!-- S: 퍼블확인용 List -->
            <!-- <div class="board-container">
                <v-simple-table class="table-board align-center">
                    <thead>
                        <tr>
                            <th style="width:7%" class="d-none d-lg-table-cell">번호</th>
                            <th style="width:66%" class="table-board__tit">제목</th>
                            <th style="width:10%">이름</th>
                            <th style="width:10%">작성일</th>
                            <th style="width:7%">조회</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="cursor:pointer; background-color:#fffcf6;">
                            <td class="d-none d-lg-table-cell">공지</td>
                            <td class="table-board__tit"><div class="ellipsis">임시로 작성된 게시글입니다.</div></td>
                            <td>홍길동</td>
                            <td>2012.12.12</td>
                            <td>123</td>
                        </tr>
                        <tr style="cursor:pointer;">
                            <td class="d-none d-lg-table-cell">1</td>
                            <td class="table-board__tit"><div class="ellipsis">임시로 작성된 게시글입니다.</div></td>
                            <td>홍길동</td>
                            <td>2012.12.12</td>
                            <td>123</td>
                        </tr>
                        <tr style="cursor:pointer;">
                            <td class="d-none d-lg-table-cell">2</td>
                            <td class="table-board__tit"><div class="ellipsis">임시로 작성된 게시글입니다.</div></td>
                            <td>홍길동</td>
                            <td>2012.12.12</td>
                            <td>123</td>
                        </tr>
                        <tr style="cursor:pointer;">
                            <td class="d-none d-lg-table-cell">3</td>
                            <td class="table-board__tit"><div class="ellipsis">임시로 작성된 게시글입니다.</div></td>
                            <td>홍길동</td>
                            <td>2012.12.12</td>
                            <td>123</td>
                        </tr>
                        <tr style="cursor:pointer;">
                            <td class="d-none d-lg-table-cell">4</td>
                            <td class="table-board__tit"><div class="ellipsis">임시로 작성된 게시글입니다.</div></td>
                            <td>홍길동</td>
                            <td>2012.12.12</td>
                            <td>123</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <div class="board-bottom">
                    <pagination-component/>
                </div>
            </div> -->
            <!-- E: 퍼블확인용 List -->
            <!-- S: 퍼블확인용 View -->
            <div class="board-container">
                <div role="table" class="board-table board-table--view">
                    <div role="rowgroup">
                        <div role="row">
                            <div role="columnheader">
                                {{ board?.subject }}
                            </div>
                        </div>
                        <div role="row">
                            <div role="cell">
                                <ul class="board-view-data">
                                    <li>작성자 : {{ board?.writer?.name }}</li>
                                    <li>작성일 : {{ board?.createdAt ? $dayjs(board.createdAt).format("YYYY-MM-DD") : "-" }}</li>
                                    <li>조회수 : {{ board?.viewCount }}</li>
                                </ul>
                            </div>
                        </div>
                        <div v-show="false" role="row">
                            <div role="cell">
                                <div class="board-view-file">
                                    <span class="head">
                                        <svg
                                            version="1.1"
                                            id="레이어_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 409 513.6"
                                            style="enable-background:new 0 0 409 513.6;"
                                            xml:space="preserve"
                                        >
                                            <path
                                                d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                            />
                                        </svg>
                                        첨부파일 :
                                    </span>
                                    <ul v-show="false" class="lists">
                                        <li><a href="">파일제목_01.jpg,</a></li>
                                        <li><a href="">파일제목_02.jpg,</a></li>
                                        <li><a href="">파일제목_03.jpg,</a></li>
                                        <li><a href="">파일제목_04.jpg,</a></li>
                                        <li><a href="">파일제목_05.jpg</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div role="row">
                            <div role="cell">
                                <div class="board-content" v-html="board?.content">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="false" role="table" class="board-table board-table--preview">
                    <div role="rowgroup">
                        <div role="row">
                            <div role="columnheader">
                                이전글
                            </div>
                            <div role="cell">
                                <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">
                                다음글
                            </div>
                            <div role="cell">
                                <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="board-buttons">
                    <div class="left">
                        <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
                    </div>
                    <!-- <div class="right">
                        <a class="button button--border" href=""><span>수정</span></a>
                        <a class="button button--border" href=""><span>답글</span></a>
                        <a class="button button--primary" href=""><span>삭제</span></a>
                    </div> -->
                </div>
            </div>
            <!-- E: 퍼블확인용 View -->
            <!-- S: 퍼블확인용 Input -->
            <!-- <div class="board-container">
                <v-simple-table class="table-board table-board--input">
                    <tbody>
                        <tr>
                            <th style="width:200px;">제목</th>
                            <td>
                                <input type="text" id="subject" class="input w-100" />
                            </td>
                        </tr>
                        <tr>
                            <th style="width:200px;">작성자 이름</th>
                            <td>
                                <input type="text" id="writer-name" class="input w-100" />
                            </td>
                        </tr>
                        <tr v-show="false">
                            <th style="width:200px;">첨부파일</th>
                            <td>
                                <div class="board-view-file">
                                    <span class="head">
                                        <svg
                                            version="1.1"
                                            id="레이어_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 409 513.6"
                                            style="enable-background:new 0 0 409 513.6;"
                                            xml:space="preserve"
                                        >
                                            <path
                                                d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                            />
                                        </svg>
                                        첨부파일 :
                                    </span>
                                    <ul v-show="false" class="lists">
                                        <li><a href="">파일제목_01.jpg,</a></li>
                                        <li><a href="">파일제목_02.jpg,</a></li>
                                        <li><a href="">파일제목_03.jpg,</a></li>
                                        <li><a href="">파일제목_04.jpg,</a></li>
                                        <li><a href="">파일제목_05.jpg</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th style="width:200px;">내용</th>
                            <td>
                                <div class="board-content">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <div v-show="false" role="table" class="board-table board-table--preview">
                    <div role="rowgroup">
                        <div role="row">
                            <div role="columnheader">
                                이전글
                            </div>
                            <div role="cell">
                                <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">
                                이전글
                            </div>
                            <div role="cell">
                                <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="board-buttons">
                    <div class="left">
                        <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
                    </div>
                    <div class="right">
                        <a class="button button--border" @click="$router.go(-1)"><span>취소</span></a>
                        <a class="button button--primary" @click="$emit('save')"><span>확인</span></a>
                    </div>
                </div>
            </div> -->
            <!-- E: 퍼블확인용 Input -->
        </div>
    </div>
</template>

<script>
import api from "@/api";
import paginationComponent from "@/components/client/control/pagination-component.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        paginationComponent,
        NaverSmarteditor,
    },
    data(){
        return {
            board: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.board = board;
        }
    }
};
</script>