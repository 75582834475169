var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-content"
    }
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('v-row', {
    staticClass: "row--medium"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "4"
      }
    }, [_c('div', {
      staticClass: "photo-item"
    }, [_c('router-link', {
      staticClass: "photo-item__inner",
      attrs: {
        "to": `/archive/${board.category}/${board._id}`
      }
    }, [_c('div', {
      staticClass: "photo-item__img",
      style: {
        backgroundImage: `url('${board.images[0]}')`
      }
    }), _c('div', {
      staticClass: "photo-item__con",
      on: {
        "click": function ($event) {
          return _vm.remember(board);
        }
      }
    }, [_c('h3', {
      staticClass: "photo-item__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('p', {
      staticClass: "photo-item__txt"
    }, [_vm._v(_vm._s(board.address))]), _c('p', {
      staticClass: "photo-item__txt"
    }, [_vm._v(_vm._s(board.area))])])])], 1)]);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }