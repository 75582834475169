<template>
    <div id="page-content">
        <div class="contents">
            <v-row class="row--medium">
                <v-col v-for="board in boards" :key="board._id" cols="12" sm="6" lg="4">
                    <div class="photo-item">
                        <router-link :to="`/archive/${board.category}/${board._id}`" class="photo-item__inner">
                            <!-- 이미지는 background-image로 넣어주세요 -->
                            <div class="photo-item__img" :style="{ backgroundImage: `url('${board.images[0]}')` }"></div>
                            <div class="photo-item__con" @click="remember(board)">
                                <h3 class="photo-item__tit">{{ board.subject }}</h3>
                                <p class="photo-item__txt">{{ board.address }}</p>
                                <p class="photo-item__txt">{{ board.area }}</p>
                            </div>
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { Header } from "@/plugins/header.js"; // Heaer 스크립트
import api from "@/api";

export default {
    data(){
        return {
            boards: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { boards } = await api.v1.boards.gets({ params: { code: "archive" }, headers: { limit: 0 } });
            this.boards = boards;
            this.header();
        },
        remember(board){
            this.$store.dispatch("remember", board);
        },
        header: function() {
            this.$nextTick(function() {
                Header(); // Heaer 스크립트 로드
            });
        },
    },
    components: {
    },
};
</script>