<template>
    <div id="page-content">
        <div class="contents">
            <div class="contact-wrap">
                <div class="contact-container">
                    <div class="logo-img">
                        <router-link to="/" class="logo-img__inner"></router-link>
                    </div>
                    <v-row class="row--medium">
                        <!-- S: SNS -->
                        <v-col cols="12" lg="5">
                            <div class="contact-items-wrap">
                                <div class="contact-items">
                                    <div class="contact-item">
                                        <a href="https://www.instagram.com/bidam_design/" target="_blank" class="contact-item__inner">
                                            <i class="icon icon-insta"></i>
                                            <strong class="contact-item__tit">디자인비담 공식 인스타그램</strong>
                                        </a>
                                    </div>
                                    <div class="contact-item">
                                        <a href="http://www.hantoday.net/news/articleList.html?page=1&total=4&box_idxno=&sc_area=I&sc_word=dyoung10" target="_blank" class="contact-item__inner">
                                            <i class="icon">H</i>
                                            <strong class="contact-item__tit">한국투데이 최동영 기자 – 분야별 전문가</strong>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="contact-img"><img src="/images/sub/contact/contact-img.jpg" alt="오시는길 이미지"></div> -->
                        </v-col>
                        <!-- E: SNS -->
                        <!-- S: Contact Us -->
                        <v-col cols="12" lg="7">
                            <div class="mb-10">
                                <h2 class="text-18 font-weight-600">Contact Us</h2>
                            </div>
                            <v-simple-table class="simple-table table-md-column">
                                <tbody>
                                    <tr>
                                        <th>기업명</th>
                                        <td>
                                            <v-text-field
                                                v-model="form.companyName"
                                                outlined
                                                hide-details="auto"
                                                placeholder="기업명을 입력해주세요"
                                            ></v-text-field>
                                        </td>
                                        <th>담당자</th>
                                        <td>
                                            <v-text-field
                                                v-model="form.writer.name"
                                                outlined
                                                hide-details="auto"
                                                placeholder="담당자를 입력해주세요"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>
                                            <v-text-field
                                                v-model="form.writer.email"
                                                outlined
                                                hide-details="auto"
                                                placeholder="00000@email.com"
                                            ></v-text-field>
                                        </td>
                                        <th>연락처</th>
                                        <td>
                                            <v-text-field
                                                v-model="form.writer.phone"
                                                outlined
                                                hide-details="auto"
                                                placeholder="000-0000-0000"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>제작구분</th>
                                        <td colspan="3">
                                            <v-radio-group v-model="form.type" row>
                                                <v-radio
                                                    label="Residence"
                                                    value="Residence"
                                                ></v-radio>
                                                <v-radio
                                                    label="Retail"
                                                    value="Retail"
                                                ></v-radio>
                                                <v-radio
                                                    label="Office"
                                                    value="Office"
                                                ></v-radio>
                                                <v-radio
                                                    label="Etc"
                                                    value="Etc"
                                                ></v-radio>
                                            </v-radio-group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>문의내용</th>
                                        <td colspan="3">
                                            <v-textarea
                                                v-model="form.content"
                                                outlined
                                                hide-details="auto"
                                                placeholder="문의내용, 예상 제작비용"
                                            ></v-textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <div class="mt-30 mt-md-20">
                                <v-btn large tile block color="primary" @click="send">문의하기</v-btn>
                            </div>
                        </v-col>
                        <!-- E: Contact Us -->
                    </v-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            form: {
                code: "contact",
                companyName: null,
                writer: {
                    name: null,
                    email: null,
                    phone: null,
                },
                type: null,
                content: null,
            }
        };
    },
    methods: {
        async send(){
            await api.v1.forms.post(this.form);
            alert("문의가 완료되었습니다");
        }
    }
};
</script>